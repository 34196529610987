import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApiMetricsRoutingModule } from './api-metrics-routing.module';
import { OverviewComponent } from './overview/overview.component';
import { CodedeployComponent } from './codedeploy/codedeploy.component';
import { MenuComponent } from './menu/menu.component';
import { CodeCoverageComponent } from './code-coverage/code-coverage.component';
import { SummaryComponent } from './summary/summary.component';
import { IconsModule } from '../icons/icons.module';


@NgModule({
  declarations: [OverviewComponent, CodedeployComponent, MenuComponent, CodeCoverageComponent, SummaryComponent],
  imports: [
    CommonModule,
    ApiMetricsRoutingModule,
    IconsModule
  ]
})
export class ApiMetricsModule { }
