<h2>List of Deployments</h2>

<div class="row">
    <div class="col-md-4 overflow-auto dep-list border-top border-bottom mt-3" *ngIf="deployments">
        <ul class="list-group" *ngIf="deployments">
            <li class="list-group-item" *ngFor="let i of deployments">{{i}} <button type="button"
                    class="btn btn-info btn-sm" (click)="getDeployment(i)">More</button>
            </li>
        </ul>
    </div>
    <div class="col-md-7 mt-3">

        <ul class="list-group" *ngIf="deployment">
            <li class="list-group-item">ID : {{deployment.deploymentId}}</li>
            <li class="list-group-item">Name : {{deployment.applicationName}}</li>
            <li class="list-group-item">Status : {{deployment.status}}</li>
            <li class="list-group-item">Created time : {{deployment.createTime}}</li>
            <li class="list-group-item">Completed time : {{deployment.completeTime}}</li>
        </ul>

    </div>
</div>



<div *ngIf="!deployments">
    Loading ....
</div>