<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">Your profile</h1>
</div>

<div class="row">
    <div class="col-md-6">
        <ul class="list-group">
            <li class="list-group-item">Email: {{email}}</li>
          </ul>
    </div>
</div>