import { Component, OnInit, EventEmitter, Output  } from '@angular/core';
import { Auth } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import { ApiMetricsServiceService } from '../service/api-metrics-service.service'
import { Deployment } from '../service/Deployment';

@Component({
  selector: 'app-codedeploy',
  templateUrl: './codedeploy.component.html',
  styleUrls: ['./codedeploy.component.css']
})
export class CodedeployComponent implements OnInit {

  count: Number = 1;
  deployments: String[]
  error: String;
  deployment: Deployment;

  constructor(private service: ApiMetricsServiceService) { }

  ngOnInit(): void {

    Auth.currentCredentials().then(res => {


      this.service.getDeploymentList(res).subscribe(
        data => {
          this.deployments = data
        },
        error => {
          this.error = "Unable to connect to the service.";
        }
      )
      
    })

  }

  getDeployment(id){

    Auth.currentCredentials().then(res => {


      this.service.getDeployment(res,id).subscribe(
        data => {
          this.deployment = <Deployment>data;
        },
        error => {
          this.error = "Unable to connect to the service.";
        }
      )
      
    })

  }

}
