import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from './../../environments/environment';
import { MorningUser } from './MorningUser';
import { Number } from 'aws-sdk/clients/iot';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiUrl = environment.amplifyConfig.API.endpoints[0].endpointWithHttp;

  constructor(private http: HttpClient) { }

  getUser(token) {

    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<MorningUser>(this.apiUrl + '/user', { headers });

  }

  getUserCount(token) {

    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<Number>(this.apiUrl + '/user/count', { headers });

  }

}
