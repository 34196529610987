import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';
import { CodedeployComponent } from './codedeploy/codedeploy.component'
import { CodeCoverageComponent } from './code-coverage/code-coverage.component'
import { SummaryComponent } from './summary/summary.component';

const routes: Routes = [
  {path: '', component: OverviewComponent, children: [
    {
      path: 'summary',
      component: SummaryComponent, // another child route component that the router renders
    },
    {
      path: 'codedeploy', // child route path
      component: CodedeployComponent, // child route component that the router renders
    },
    {
      path: 'code-coverage',
      component: CodeCoverageComponent, // another child route component that the router renders
    },
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ApiMetricsRoutingModule { }
