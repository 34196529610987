<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">API Metrics and Backend details</h1>
</div>

<app-menu></app-menu>

<!-- <div class="container">
    <div class="row">
        <router-outlet></router-outlet>
    </div>
</div> -->

<router-outlet class="p-3"></router-outlet>