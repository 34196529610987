import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';

import { HomeModule } from './home/home.module';
import { AppRoutingModule } from './app-routing.module';
import { IconsModule } from './icons/icons.module';
import { SwaggerModule } from './swagger/swagger.module';
import { ProfileModule } from './profile/profile.module';
import { RecipeModule } from './recipe/recipe.module';
import { ApiMetricsModule } from './api-metrics/api-metrics.module';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule, AmplifyUIAngularModule, HomeModule, AppRoutingModule, IconsModule, SwaggerModule, ProfileModule, RecipeModule, ApiMetricsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
