import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as AWS from 'aws-sdk';
import { Deployment } from '../service/Deployment';

@Injectable({
  providedIn: 'root'
})
export class ApiMetricsServiceService {

  constructor() { }

  getDeploymentList(creds): Observable<Array<String>> {

    const deployments = new Array<String>();

    var codedeploy = new AWS.CodeDeploy({ credentials: creds, apiVersion: '2014-10-06', region: "ap-southeast-1" });

    var params = {
      applicationName: 'AppECS-morning-api-dev-tomcat-java-service-fargate',
      deploymentGroupName: 'DgpECS-morning-api-dev-tomcat-java-service-fargate',
      includeOnlyStatuses: [
        "Created", "Queued", "InProgress", "Succeeded", "Failed", "Stopped", "Ready"
        /* more items */
      ]
    };
    codedeploy.listDeployments(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        for (var d in data.deployments) { 
          deployments.push(data.deployments[d]);
        }
      }
    });

    return of(deployments);
  }

  getDeployment(creds, id): Observable<Deployment> {

    var codedeploy = new AWS.CodeDeploy({ credentials: creds, apiVersion: '2014-10-06', region: "ap-southeast-1" });

    const deployment = new Deployment();

    var params = {
      deploymentId: id /* required */
    };
    codedeploy.getDeployment(params, function(err, data) {
      if (err) console.log(err, err.stack); // an error occurred
      else {
        deployment.applicationName = data.deploymentInfo.applicationName;
        deployment.deploymentId = data.deploymentInfo.deploymentId;
        deployment.status = data.deploymentInfo.status;
        deployment.createTime = data.deploymentInfo.createTime;
        deployment.completeTime = data.deploymentInfo.completeTime;
      };           // successful response
    });

    return of(deployment);
  }

}