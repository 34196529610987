<div class="alert alert-dark mt-2" id="api-details" [style.background-color]="backColor" role="alert">
    API {{envname}} [{{apihost}}]
</div>

<div class="alert alert-danger" role="alert">
    Your Identity id: {{identityId}}
</div>

<div class="alert alert-danger" role="alert">
    Your Token:
    <div class="text-break access-token">{{accessToken}}</div>
</div>

<div class="accordion" id="accordionExample">

    <div class="card">
        <div class="card-header" id="headingOne">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Error codes
                </button>
            </h2>
        </div>

        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body">
                <p>These errors could occur when communicating with the API</p>
                Error codes are,
                    <ul>
                        <li>
                            400 (API Error). Check Each API for possible error codes and domain.
                        </li>
                        <li>
                            401 (UnauthorizedError). This could occur if you provide an invalid Bearer token or expired Bearer token.
                        </li>
                        <li>
                            412 (Access denied). When your don't have the permission to access the API.This API has few access levels.
                            Check each API for relevant access levels
                            <ol>
                                <li>
                                    ADMIN
                                </li>
                                <li>
                                    ROASTER
                                </li>
                                <li>
                                    USER
                                </li>
                                <li>
                                    MACHINE
                                </li>
                            </ol>
                        </li>
                    </ul>
                Example Error:
                <pre class="bg-dark text-white">
                        <code>
                        {{"{
                            timestamp: '2021-05-20T05:24:48.629933Z',
                            status: 400,
                            domain: 'COMMON',
                            code: 4005,
                            message: 'Please check your input data',
                            errors: [
                              'bloomDuration:must be greater than or equal to 1'
                            ]
                          }"}}
                        </code>
                    </pre>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col">Code</th>
                                <th scope="col">Message</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>4000</td>
                                <td>MORNING_API_ERROR</td>
                            </tr>
                            <tr>
                                <td>4004</td>
                                <td>ITEM_NOT_FOUND</td>
                            </tr>
                            <tr>
                                <td>4005</td>
                                <td>INPUT_DATA_ERROR</td>
                            </tr>
                            <tr>
                                <td>5000</td>
                                <td>INVALID_PRESSURE_PROFILE</td>
                            </tr>
                            <tr>
                                <td>5001</td>
                                <td>DEFAULT_RECIPE_ERROR</td>
                            </tr>
                            <tr>
                                <td>5002</td>
                                <td>MAXIMUM_MACHINE_RECIPE_COUNT_ERROR</td>
                            </tr>
                            <tr>
                                <td>5003</td>
                                <td>MACHINE_COMMAND_ERROR</td>
                            </tr>
                            <tr>
                                <td>5004</td>
                                <td>UNSUPPORTED_TYPE_ERROR</td>
                            </tr>
                            <tr>
                                <td>5005</td>
                                <td>RECIPE_INDEX_ERROR</td>
                            </tr>
                            <tr>
                                <td>5006</td>
                                <td>ITEM_NOT_FOUND_BY_ID_USERID</td>
                            </tr>
                            <tr>
                                <td>5007</td>
                                <td>UNABLE_TO_UPDATE_SHADOW</td>
                            </tr>
                            <tr>
                                <td>5008</td>
                                <td>ROASTER_ERROR</td>
                            </tr>
                            <tr>
                                <td>5009</td>
                                <td>USER_ERROR</td>
                            </tr>
                            <tr>
                                <td>5010</td>
                                <td>MACHINE_ERROR</td>
                            </tr>
                            <tr>
                                <td>5011</td>
                                <td>CAPSULE_ERROR</td>
                            </tr>

                            <tr>
                                <td>5012</td>
                                <td>INPUT_NAME_ERROR</td>
                            </tr>
                            <tr>
                                <td>5013</td>
                                <td>NO_CONNECTED_N_ACTIVE_MACHINES_ERROR</td>
                            </tr>
                            <tr>
                                <td>5014</td>
                                <td>NUMBER_OF_CONNECTED_N_ACTIVE_MACHINES_ERROR</td>
                            </tr>
                            <tr>
                                <td>5015</td>
                                <td>ENERGYSAVE_TIMEOUT_ERROR</td>
                            </tr>
                            <tr>
                                <td>5016</td>
                                <td>ROASTER_NOT_APPROVED_ERROR</td>
                            </tr>
                            <tr>
                                <td>5017</td>
                                <td>ROASTER_FEATURED_ERROR</td>
                            </tr>
                            <tr>
                                <td>5018</td>
                                <td>ROASTER_FEATURED_LIMIT_ERROR</td>
                            </tr>
                            <tr>
                                <td>5019</td>
                                <td>ROASTER_FEATURED_ORDER_ERROR</td>
                            </tr>
                            <tr>
                                <td>5020</td>
                                <td>ROASTER_FEATURED_ORDER_EXIST_ERROR</td>
                            </tr>
                            <tr>
                                <td>5021</td>
                                <td>ROASTER_FEATURED_INPUT_ERROR</td>
                            </tr>
                            <tr>
                                <td>5022</td>
                                <td>USER_MACHINE_DEFAULT_RECIPE_ERROR</td>
                            </tr>
                            <tr>
                                <td>5023</td>
                                <td>MAXIMUM_MACHINE_DEFAULT_RECIPE_COUNT_ERROR</td>
                            </tr>

                            <tr>
                                <td>5024</td>
                                <td>MACHINE_EXISTS_ERROR</td>
                            </tr>

                            <tr>
                                <td>5025</td>
                                <td>MACHINE_RECIPE_SYNC_ERROR</td>
                            </tr>

                            <tr>
                                <td>5026</td>
                                <td>MACHINE_RECIPE_NO_PARENT_ERROR</td>
                            </tr>

                            <tr>
                                <td>5027</td>
                                <td>WORK_IN_PROGRESS</td>
                            </tr>

                            <tr>
                                <td>5028</td>
                                <td>ROASTER_RECIPE_CAPSULE_INVALID</td>
                            </tr>

                            <tr>
                                <td>5030</td>
                                <td>USER_DEFAULT_RECIPE_ERROR</td>
                            </tr>

                            <tr>
                                <td>5031</td>
                                <td>ACCESS_DENIED</td>
                            </tr>

                            <tr>
                                <td>5033</td>
                                <td>MACHINE_ECOMODE_ERROR</td>
                            </tr>

                            <tr>
                                <td>5034</td>
                                <td>MACHINE_NOTIDLE_ERROR</td>
                            </tr>

                            

                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-header" id="headingOne">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                    data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                    MQTT Error codes
                </button>
            </h2>
        </div>

        <div id="collapseThree" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body">
                <p>These errors occur when communicating with the machine via MQTT</p>
                <p>For all the error messages in this section, the status code is 503</p>

                Example Error:
                <pre class="bg-dark text-white">
                        <code>
                        {{"{
                            timestamp: '2021-05-20T05:08:24.464074Z', 
                            status: 503,
                            domain: 'RECIPE',
                            code: 6004,
                            message: 'Machine is busy',
                            errors: [
                                'Machine is busy'
                            ]
                        }"}}
                        </code>
                    </pre>

                <p>
                    <a href="https://drinkmorning.atlassian.net/wiki/spaces/MORNING/pages/2752513/Machine+-+Mobile+Communication"
                        target="_blank">More information in Confluence documentation</a>
                </p>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col">Code</th>
                                <th scope="col">Message</th>
                                <th scope="col">Domains</th>
                                <th scope="col">When ?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>6001</td>
                                <td>MACHINE_TABLE_READ_ERROR</td>
                                <td>RECIPE</td>
                                <td>Unable to read data from Database</td>
                            </tr>
                            <tr>
                                <td>6004</td>
                                <td>MACHINE_IS_BUSY_ERROR</td>
                                <td>RECIPE</td>
                                <td>The machine is busy and not ready to brew</td>
                            </tr>
                            <tr>
                                <td>6007</td>
                                <td>DATABASE_SECRET_ERROR</td>
                                <td>RECIPE</td>
                                <td>Unable to read data from AWS</td>
                            </tr>
                            <tr>
                                <td>6008</td>
                                <td>MACHINE_IN_ECO_MODE</td>
                                <td>RECIPE</td>
                                <td>The machine is in eco mode</td>
                            </tr>
                            <tr>
                                <td>5004</td>
                                <td>UNSUPPORTED_TYPE_ERROR</td>
                                <td>RECIPE</td>
                                <td>Unsupported Recipe</td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-header" id="headingTwo">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                    data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Security
                </button>
            </h2>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
            <div class="card-body">
                <p>
                    After a successful user pool sign-in, your web or mobile app will receive user pool tokens from
                    Amazon Cognito. You can use those tokens to control access to
                    your server-side resources. You can also create user pool groups to manage permissions, and to
                    represent different types of users. For more information on
                    using groups to control access your resources
                    <br>
                    <a href="https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-scenarios.html#scenario-backend"
                        target="_blank">
                        https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-scenarios.html#scenario-backend
                    </a>
                    <br><br>
                    Use the token you obtained from AWS Cognito to securely communicate with the API. Use the token
                    as
                    a Bearer token. Bearer Tokens are the predominant type of access token used with OAuth 2.0.
                    <br>
                    <a href="https://oauth.net/2/bearer-tokens/#:~:text=Bearer%20Tokens%20are%20the%20predominant,such%20as%20JSON%20Web%20Tokens."
                        target="_blank">
                        RFC 6750: OAuth 2.0 Bearer Token Usage
                    </a>
                    <br><br>
                    <a href="https://docs.google.com/spreadsheets/d/1V3lsrQc2Y_BpXBBUTw7OC1PrTa5w5RsXBCYMF7zAAC4/edit?usp=sharing"
                        target="_blank">
                        Cognito User Pool Details
                    </a>
                    
                </p>

                <p>
                    For APIs that work with the machine, we are using Client credentials grant.
                    <br>
                    <a href="https://aws.amazon.com/blogs/mobile/understanding-amazon-cognito-user-pool-oauth-2-0-grants/"
                        target="_blank">
                        https://aws.amazon.com/blogs/mobile/understanding-amazon-cognito-user-pool-oauth-2-0-grants/
                    </a>
                </p>
            </div>
        </div>
    </div>

</div>

<div class="swagger-container"></div>
<button (click)="backToTop()" class="btn btn-info m-1" id="back-to-top">&#8679;</button>