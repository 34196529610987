import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-code-coverage',
  templateUrl: './code-coverage.component.html',
  styleUrls: ['./code-coverage.component.css']
})
export class CodeCoverageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
