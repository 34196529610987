import { Recipe } from './recipe';

export class CoffeeRecipe extends Recipe {

    public bloomDuration: number;
    public massOutput: any;
    public pressureProfile: PressureProfile;
    public pressureProfilePresetId: number;
    public capsule: Capsule;

}

export class PressureProfile {
    constructor(
        public stageDtos: any[]
    ) { }
}

export class Capsule {
    constructor(
        public id: number,
        public name: string,
        public producer: string
    ) { }
}