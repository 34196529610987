import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './default/default.component';
import { DefaultMachineComponent } from './default-machine/default-machine.component';
import { OverviewComponent } from './overview/overview.component';

const routes: Routes = [
  {path: '', component: OverviewComponent, children: [
    {
      path: 'default',
      component: DefaultComponent, // another child route component that the router renders
    },
    {
      path: 'machine-default', // child route path
      component: DefaultMachineComponent, // child route component that the router renders
    }
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecipeRoutingModule { }
