import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

  public href: string = "";

  constructor(private router: Router, private route: ActivatedRoute) { }


  ngOnInit(): void {

    if (this.router.url == '/recipe') {
      this.router.navigate(['default'], {relativeTo: this.route});
    }
    
  }

}
