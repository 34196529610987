<ul class="nav nav-tabs">
    <li class="nav-item">
        <a class="nav-link" routerLink="/metrics/summary" routerLinkActive="active">Summary</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLink="/metrics/codedeploy" routerLinkActive="active" >Deployments</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLink="/metrics/code-coverage" routerLinkActive="active">Code coverage</a>
    </li>
</ul>