import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {

  email: String;

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit(): void {

    Auth.currentUserInfo().then(res => {
      this.email = res.attributes.email;
      this.ref.detectChanges();
    })
    
  }

}
